import React, {Component} from 'react';

class Header extends Component {
    render() {
        return (
            <div>
                <span className="logo">Fix<span className="green_txt">us</span>Pix<span className="green_txt">us</span></span>
            </div>
        );
    }
}

export default Header;