import React, {Component} from 'react';
import IndexPage from './IndexPage';
import RegPage from './RegPage';
import SearchPage from './SearchPage'; // страницы 3-4
import FinishPage from './FinishPage'; // страницы 3-4
import $ from 'jquery';
import './App.css';
//import data from './data';


class App extends Component {
  state = {
    action: "index",
    subAction: null,
    sub2Action: null,
    user: {},
    data: {},
    profile: {
      counter: 0,
      name: null,
      loc: null,
      img: null,
      isFinish: false,
      is_like: false,
      prevProfiles: [],
      lakedProfiles: []
    }
  };
  stateDef = {
    action: "index",
    subAction: null,
    sub2Action: null,
    user: {},
    profile: {
      counter: 0,
      name: null,
      loc: null,
      img: null,
      isFinish: false,
      is_like: false,
      prevProfiles: [],
      lakedProfiles: []
    }
  };

  constructor(props) {
    super(props);
    this.handleToPage = this.handleToPage.bind(this);
    this.setProfile = this.setProfile.bind(this);
    this.setUser = this.setUser.bind(this);
    if (!window.App) {
      window.App = this;
    }
    $.getJSON('/data.json', function (data) {
      window.App.data = data;
    });


  }

  setUser(user) {
    var self = this;
    this.setState({user: user}, () => {
      self.handleToPage('search');
    });
  }

  setProfile(profile) {
    var self = this;
    setTimeout(function () {
      self.setState({
        action: 'search',
        profile: profile,
      });
    }, 800);
  }

  handleToPage(a, b, c) {
    var self = this;
    document.activeElement.blur();
    $("#root").fadeOut(1000, function () {
      setTimeout(function () {
        self.setState({
          action: a,
          subAction: b,
          sub2Action: c,
        }, function () {
          if(a === "index"){
            $('[type="text"], [type="email"]').val("");
            $('input.error, select.error').removeClass('error');
            $('div.error-message').text('');
            $('[type="radio"]').prop('checked', false);
            $("select option").prop("selected", false);
            $(".jcf-select-text span").text('');
            self.setState(self.stateDef);
          }
          $(window).scrollTop(0);
          $("#root").fadeIn(300);
        });
      }, 500);

    });

  }

  pageState() {
    window.view_profile = [];
    var base = {
      IndexPage: "none",
      RegPage: "none",
      SearchPage: "none",
      FinishPage: "none"
    };
    $('#header .logo').removeClass('logo_sm');
    $('body').removeClass('bg_grey');
    switch (this.state.action) {
      default:
      case "index":
        base.IndexPage = "block";
        break;
      case "reg":
        base.RegPage = "block";
        break;
      case "search":
        $('#header .logo').addClass('logo_sm');
        $('body').addClass('bg_grey');
        base.SearchPage = "block";
        break;
      case "finish":
        $('#header .logo').addClass('logo_sm');
        $('body').addClass('bg_grey');
        base.FinishPage = "block";
        break;
    }
    return base;
  }
  idleTimer = null;
  idleWait = 60000; // время ожидания в мс. (1/1000 секунды)
  idleWaitFinish = 60000; // время  последней страницы
  idleWaitReg = 60000; // Время страницы регистрации
  setTimer = false;
  render() {
    var self = this;
    var state = this.pageState();
    if(this.setTimer){
      $(document).off('mousemove keydown scroll touchstart touchend touchmove click');
      this.setTimer = false;
    }
    if(!this.setTimer){
      $(function(){
        $(document).on('mousemove keydown scroll touchstart touchend touchmove click', function(){
          clearTimeout(self.idleTimer);
          var timer = self.idleWait;
          if (state.RegPage === "block") {
            timer = self.idleWaitReg;
          }else if(state.FinishPage === "block"){
            timer = self.idleWaitFinish;
          }
          self.idleTimer = setTimeout(function(){
            let state = self.pageState();
            if (state.IndexPage !== "block") {
              $("input:focus").blur();
              self.handleToPage('index');
              console.log(timer);
            }
          }, timer);
        });
        self.setTimer = true;
      });
    }

    return (
        <React.Fragment>
          <IndexPage handleToPage={this.handleToPage} display={state.IndexPage} states={this.state}/>
          <RegPage setUser={this.setUser} handleToPage={this.handleToPage} display={state.RegPage}
                   action={this.state.subAction}/>
          <SearchPage data={this.data} user={this.state.user} profile={this.state.profile} setProfile={this.setProfile}
                      handleToPage={this.handleToPage} display={state.SearchPage} action={this.state.subAction}/>
          <FinishPage profile={this.state.profile} data={this.data} user={this.state.user} handleToPage={this.handleToPage} display={state.FinishPage}
                      action={this.state.subAction}/>
        </React.Fragment>
    );
  }
}

export default App;
