import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Header from './components/Header';
import * as serviceWorker from './serviceWorker';


function preloadImages(paths, callback) {
    var images = [];
    var loaded = 0;

    paths.forEach(function (path) {
        var img = new Image();
        img.src = path;
        img.onload = onImagePreloaded;
        images.push(img);
    });


    function onImagePreloaded() {
        loaded++;
        if (loaded === paths.length && callback) {
            callback(images);
        }
    }
}
/*
preloadImages(['/img/flower.png',
    '/img/icon1.png',
    '/img/icon2.png',
    '/img/icon_close.png',
    '/img/icon_like.png',
    '/img/img1.jpg',
    '/img/img2.png',
    '/img/pin.png',
    '/img/fotos/humans/2227230159.jpg',
    '/img/fotos/humans/2272775066.jpg',
    '/img/fotos/humans/2329782471.jpg',
    '/img/fotos/humans/2504849028.jpg',
    '/img/fotos/humans/2545410937.jpg',
    '/img/fotos/humans/2625797599.jpg',
    '/img/fotos/humans/2664447991.jpg',
    '/img/fotos/humans/2668387139.jpg',
    '/img/fotos/humans/2670557354.jpg',
    '/img/fotos/humans/2759486571.jpg',
    '/img/fotos/humans/2761246096.jpg',
    '/img/fotos/humans/2765780393.jpg',
    '/img/fotos/humans/2778308062.jpg',
    '/img/fotos/humans/2778758113.jpg',
    '/img/fotos/humans/2782274556.jpg',
    '/img/fotos/humans/689111324.jpg',
    '/img/fotos/humans/Amir.jpg',
    '/img/fotos/humans/Andrey.jpg',
    '/img/fotos/humans/Anton.jpg',
    '/img/fotos/humans/Artem.jpg',
    '/img/fotos/humans/comment_30k65Yvv6i2CfmTm55mhPRJhbHC7J3kL.jpg',
    '/img/fotos/humans/Dmitry.jpg',
    '/img/fotos/humans/Harvey.jpg',
    '/img/fotos/humans/Ignat.jpg',
    '/img/fotos/humans/Ivan.jpg',
    '/img/fotos/humans/Jacky.jpg',
    '/img/fotos/humans/Nick.jpg',
    '/img/fotos/humans/Pavel.jpg',
    '/img/fotos/humans/pg-42-poetry-1-rex.jpg',
    '/img/fotos/humans/Rasta.jpg',
    '/img/fotos/humans/rP7f--jhp4A.jpg',
    '/img/fotos/humans/Sergey.jpg',
    '/img/fotos/humans/Tanya.jpg',
    '/img/fotos/humans/Vassily.jpg',
    '/img/fotos/humans/Vlad.jpg',
    '/img/fotos/humans/Vladimir.jpg',
    '/img/fotos/humans/Vladislav.jpg',
    '/img/fotos/humans/Wang.jpg',
    '/img/fotos/humans/Yuto.jpg',
    '/img/fotos/humans/Ангелина.jpg',
    '/img/fotos/humans/Андрюха.jpg',
    '/img/fotos/humans/Армен.jpg',
    '/img/fotos/humans/Борис Родин.jpg',
    '/img/fotos/humans/Вероника.jpg',
    '/img/fotos/humans/Виолетта.jpg',
    '/img/fotos/humans/Георгий.jpg',
    '/img/fotos/humans/Давид.jpg',
    '/img/fotos/humans/Дарья.jpg',
    '/img/fotos/humans/Дмитрий.jpg',
    '/img/fotos/humans/Егор.jpg',
    '/img/fotos/humans/Елена.jpg',
    '/img/fotos/humans/Ефрем.jpg',
    '/img/fotos/humans/Иракли.jpg',
    '/img/fotos/humans/Капитан.jpg',
    '/img/fotos/humans/Макар.jpg',
    '/img/fotos/humans/Маша.jpg',
    '/img/fotos/humans/Миха.jpg',
    '/img/fotos/humans/Михаил С.jpg',
    '/img/fotos/humans/Михаил.jpg',
    '/img/fotos/humans/Навин.jpg',
    '/img/fotos/humans/Надежда.jpg',
    '/img/fotos/humans/Николай.jpg',
    '/img/fotos/humans/Олег.jpg',
    '/img/fotos/humans/Оленька.jpg',
    '/img/fotos/humans/Ольга.jpg',
    '/img/fotos/humans/Оля.jpg',
    '/img/fotos/humans/Пахом.jpg',
    '/img/fotos/humans/Саня.jpg',
    '/img/fotos/humans/Святослав.jpg',
    '/img/fotos/humans/Сергей Сусляков.jpg',
    '/img/fotos/humans/Сергей.jpg',
    '/img/fotos/humans/Серёга.jpg',
    '/img/fotos/humans/Станислав.jpg',
    '/img/fotos/humans/Тамара.jpg',
    '/img/fotos/humans/Татьяна.jpg',
    '/img/fotos/humans/Эрнест.jpg',
    '/img/fotos/humans/Ярослав.jpg',
    '/img/fotos/plant/cornstalk dracen.jpg',
    '/img/fotos/plant/Notocactus magnificus.jpg',
    '/img/fotos/plant/Prickly pears.jpg',
    '/img/fotos/plant/shefflera-1-11928861.jpg',
    '/img/fotos/plant/Snake Plant (Sansevieria).jpg',
    '/img/fotos/plant/Азалия комнатная.jpg',
    '/img/fotos/plant/Азалия садовая комнатная.jpg',
    '/img/fotos/plant/аспидистра.jpg',
    '/img/fotos/plant/Бегония.jpg',
    '/img/fotos/plant/Гардения.jpg',
    '/img/fotos/plant/Герань цветущая.jpg',
    '/img/fotos/plant/Гибискус бабушкин махровый.jpg',
    '/img/fotos/plant/Гибискус домашний.jpg',
    '/img/fotos/plant/Гибискус комнатный.jpg',
    '/img/fotos/plant/Гиппеаструм 1.jpg',
    '/img/fotos/plant/Гиппеаструм.jpg',
    '/img/fotos/plant/Глоксиния Брокада красная.jpg',
    '/img/fotos/plant/Глоксиния Брокада красная2.jpg',
    '/img/fotos/plant/Глоксиния розовая.jpg',
    '/img/fotos/plant/гортензия .jpg',
    '/img/fotos/plant/Дендробиум кингианум.jpg',
    '/img/fotos/plant/Денежное дерево Толстянка.jpg',
    '/img/fotos/plant/Денежное дерево.jpg',
    '/img/fotos/plant/драцена 7.jpg',
    '/img/fotos/plant/Драцена.jpg',
    '/img/fotos/plant/Дроцена Фрагранс Дорадо.jpg',
    '/img/fotos/plant/Жёлтый кактус.jpg',
    '/img/fotos/plant/Кактус 5.jpg',
    '/img/fotos/plant/кактус 8.jpg',
    '/img/fotos/plant/Кактус 88.jpg',
    '/img/fotos/plant/Кактус Маккой.jpg',
    '/img/fotos/plant/Кактус Маккой — копия.jpg',
    '/img/fotos/plant/Кактус мохнатый белый.jpg',
    '/img/fotos/plant/Кактус Цереус.jpg',
    '/img/fotos/plant/Кактус цереус — копия.jpg',
    '/img/fotos/plant/Каланхоэ.jpg',
    '/img/fotos/plant/Кливия киноварная.jpg',
    '/img/fotos/plant/Комнатный плющ.jpg',
    '/img/fotos/plant/Красолу арборесценс.jpg',
    '/img/fotos/plant/Крестовник стрелконосный.jpg',
    '/img/fotos/plant/куст домашней гортензии.jpg',
    '/img/fotos/plant/Лизиантус.jpg',
    '/img/fotos/plant/Маммиллярия матуда 1.jpg',
    '/img/fotos/plant/Маммиллярия матуда.jpg',
    '/img/fotos/plant/Махровый Абутилон.jpg',
    '/img/fotos/plant/Молочай.jpg',
    '/img/fotos/plant/Нокактус.jpg',
    '/img/fotos/plant/Нолина Бокарнея .jpg',
    '/img/fotos/plant/Нотокактус.jpg',
    '/img/fotos/plant/Опунция мелковолосистая.jpg',
    '/img/fotos/plant/Орхидея Венерин Башмачок.jpg',
    '/img/fotos/plant/пальма домашняя 2.jpg',
    '/img/fotos/plant/пальма домашняя.jpg',
    '/img/fotos/plant/пальма комнатная.jpg',
    '/img/fotos/plant/пальма комнатная2.jpg',
    '/img/fotos/plant/Пахифитум_Овиферум.jpg',
    '/img/fotos/plant/Пеперомия висячая.jpg',
    '/img/fotos/plant/Пералгония.jpg',
    '/img/fotos/plant/Плюмерия.jpg',
    '/img/fotos/plant/Полисциас Фабиан.jpg',
    '/img/fotos/plant/Птицемлечник Тирсовидный.jpg',
    '/img/fotos/plant/Сансевьера трехполосная.jpg',
    '/img/fotos/plant/Сансевьера трехполосная1.jpg',
    '/img/fotos/plant/Сансевьера.5.jpg',
    '/img/fotos/plant/Сансивиерия трёхполосная.jpg',
    '/img/fotos/plant/Седеверия суккулент.jpg',
    '/img/fotos/plant/Стробилантес Дайера.jpg',
    '/img/fotos/plant/Суккулент Анакампсерос.jpg',
    '/img/fotos/plant/Суккулент белый.jpg',
    '/img/fotos/plant/Суккулент остролист.jpg',
    '/img/fotos/plant/Суккулент похифитум.jpg',
    '/img/fotos/plant/суккулент.jpg',
    '/img/fotos/plant/Суккулент10.jpg',
    '/img/fotos/plant/Фиалка Оптимара.jpg',
    '/img/fotos/plant/Фиалка.jpg',
    '/img/fotos/plant/Фикус Барок.jpg',
    '/img/fotos/plant/Фикус Бенжамин.jpg',
    '/img/fotos/plant/фикус Бенжамина 2.jpg',
    '/img/fotos/plant/Фикус Бенжамина 5.jpg',
    '/img/fotos/plant/Фикус Бенжамина 6.jpg',
    '/img/fotos/plant/Фикус Бенжамина.jpg',
    '/img/fotos/plant/фикус каучуконосный 2.jpg',
    '/img/fotos/plant/фикус каучуконосный 3.jpg',
    '/img/fotos/plant/фикус каучуконосный.jpg',
    '/img/fotos/plant/Фикус Лирата 2.jpg',
    '/img/fotos/plant/Фикус Лирата 8.jpg',
    '/img/fotos/plant/Фикус Лирата.jpg',
    '/img/fotos/plant/Фикус пеперомия.jpg',
    '/img/fotos/plant/Филодендрон.jpg',
    '/img/fotos/plant/Хлорофитум хохлатый оушен.jpg',
    '/img/fotos/plant/Хойя Париклада Вариегата.jpg',
    '/img/fotos/plant/Цветок оленьи рога.jpg',
    '/img/fotos/plant/Цветок Шеффлера.jpg',
    '/img/fotos/plant/Цветущая Азуреа.jpg',
    '/img/fotos/plant/Цветущая опунция.jpg',
    '/img/fotos/plant/цветущий кактус суккулент .jpg',
    '/img/fotos/plant/Цереус кактус.jpg',
    '/img/fotos/plant/Эхинопсис Эйриеза.jpg',
    '/img/fotos/plant/Эхинопсис эрье.jpg'], function () {

})

 */
ReactDOM.render(<Header/>, document.getElementById('header'));
ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();