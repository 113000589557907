import React, {Component} from 'react';
import $ from "jquery";

class SearchPage extends Component {
    state = {
        counter: 0,
        name: null,
        loc: null,
        img: null,
        isFinish: false
    };

    constructor(props) {
        super(props);
        this.handleLike = this.handleLike.bind(this);
        this.nextProfile = this.nextProfile.bind(this);
        this.handleFinis = this.handleFinis.bind(this);

    }


    handleLike(event) {
        event.preventDefault();
        var element = event.target,
            self = this;

        $(element).addClass('pumpAnim').delay(600).queue(function(){
            $(element).removeClass("pumpAnim").dequeue();
        });

        self.nextProfile($(element).is('.like_btn'));
    }

    handleFinis(event) {
        this.props.handleToPage("finish");
        event.preventDefault();
    }

    filterProfiles(profiles, prevProfiles) {
        return profiles.filter(function (e) {
            return !prevProfiles.find(function (e2) {
                return e2 === e
            })
        })
    }

    getRand(items) {
        return items[Math.floor(Math.random() * items.length)];
    }

    nextProfile(is_like) {
        var self = this;
        if (is_like === true) {
            this.props.profile.lakedProfiles.push({
                name: self.props.profile.name,
                img: self.props.profile.img,
            });
        }
        if (this.props.profile.is_like) {
            is_like = this.props.profile.is_like;

        }
        var type = (this.props.user.user_type === 'humans' ? "plant" : "humans");
        var profiles = this.filterProfiles(this.props.data[type], this.props.profile.prevProfiles);
        if (profiles.length === 0) {
            this.props.profile.prevProfiles = [];
            var profiles = this.filterProfiles(this.props.data[type], this.props.profile.prevProfiles);
        }

        var profile = this.getRand(profiles);
        var img = profile[0];
        var name = profile[1];
        this.props.profile.prevProfiles.push(profile);

        var loc = this.getRand(this.props.data.locations),
            count = this.props.profile.counter + 1,
            isFinish = this.props.profile.isFinish,
            dir = type + "/",
            prevProfiles = this.props.profile.prevProfiles,
            lakedProfiles = this.props.profile.lakedProfiles;

        if ((count > 4 && count < 9) && !isFinish) {
            if (this.getRand([true, false])) {
                isFinish = (is_like === true);
            }
        }
        if (count > 9) {
            count = 1;
        }


        console.log(this.props.profile);
        this.props.setProfile({
            isFinish: isFinish,
            is_like: is_like,
            counter: count,
            name: name,
            loc: loc,
            prevProfiles: prevProfiles,
            lakedProfiles: lakedProfiles,
            img: "url('/img/fotos/" + dir + img + "')",
        });
    }

    render() {
        const {display} = this.props;
        const {name, loc, img, isFinish} = this.props.profile;
        const dsp_no = (isFinish ? "none" : "block");
        const dsp_yes = (isFinish ? "inherit" : "none");
        if (this.props.display === "block" && this.props.profile.counter === 0) {
            this.nextProfile();
        }
        return (
            <div className="container" style={{display: display}}>
                <div className="img_hold" style={{backgroundImage: img}}>
                    <a className="item_btn esc_btn" href="#" onClick={this.handleLike}/>
                    <a className="item_btn like_btn" href="#" onClick={this.handleLike}/>
                    <div className="img_hold_text">
                        {name}
                        <span className="loc">{loc} км</span>
                    </div>
                </div>
                <div className="over_txt_hold">
                    <span className="over_txt" style={{display: dsp_no}}>Пока совпадений нет</span>
                    <span className="over_txt" style={{display: dsp_yes}}>У вас одно совпадение</span>
                    <a className="btn" href="#" onClick={this.handleFinis} style={{display: dsp_yes}}><span
                        className="btn_in">ПОСМОТРЕТЬ</span></a>
                </div>
            </div>
        );
    }
}

export default SearchPage;