import React, {Component} from 'react';
import $ from "jquery";

class FinishPage extends Component {
    constructor(props) {
        super(props);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleSend = this.handleSend.bind(this);
    }

    handleSearch(event) {
        this.props.handleToPage("search");
        event.preventDefault();
    }

    handleSend(event) {
        var el = $(event.target).parent().parent();
        if (el.hasClass('animate')) {
            el.removeClass('animate');
            el.addClass('animate2');
        } else {
            el.removeClass('animate2');
            el.addClass('animate');
        }
        event.preventDefault();
    }

    getRand(items) {
        return items[Math.floor(Math.random() * items.length)];
    }

    getProfile() {
        var name = "",
            img = "",
            profiles = [];

        if (this.props.display === "block") {
            profiles = this.getRand(this.props.profile.lakedProfiles);
            img = profiles.img;
            name = profiles.name;

            return {name: name, img: img}
        } else {
            return {name: "", img: ""}
        }
    }

    render() {
        const {display} = this.props;
        var profile = this.getProfile(),
            img;

        switch (this.props.user.user_type) { //Выбор формы растение человек
            case "humans": //Если человек то показываем цветы
                img = "url(/img/img2.png)";
                break;
            case "plant": //Если цветоа то показываем людей
                img = "url(/img/flower.png)";
                break;
        }
        if(window.user_photo){
            img = "url("+window.user_photo+")";
        }

        return (
            <div className="container" style={{display: display}}>
                <div className="result_block_wrap">
                    <div className="result_title">
                        You are picked!
                    </div>
                    <div className="result_block_cont">
                        <div className="result_block_hold">
                            <div className="result_block" style={{backgroundImage: img}}>
                                <span className="result_block_name">Вы</span>
                            </div>
                            <span className="result_block_icon"/>
                            <div className="result_block" style={{backgroundImage: profile.img}}>
                                <span className="result_block_name">{profile.name}</span>
                            </div>
                        </div>
                        <div className="btn_message_hold">
                            <a className="btn btn_white btn_message" href="#" onClick={this.handleSend}><span
                                className="btn_in">Написать сообщение</span></a>
                            <span className="btn_message_text animate">Oops! <span className="small">Недоступно в демо-версии</span></span>
                        </div>
                        <a className="btn btn_white btn_srch" href="#" onClick={this.handleSearch}><span
                            className="btn_in">Продолжить поиск</span></a>
                    </div>
                </div>
                <div className="big_txt2">
                    You fix us if you pick us!
                </div>
            </div>
        );
    }
}

export default FinishPage;