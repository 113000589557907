import React, {Component} from 'react';
import PropTypes from 'prop-types';

class IndexPage extends Component {
    render() {
        const { display } = this.props;
        return (
            <div className="container" style={{display:display}}>
                <a className="main_img" href="#" onClick={(e) => {e.preventDefault(); this.props.handleToPage("reg")}}><img src="/img/img1.jpg" width="1280" height="1280" alt="image"/></a>
                <div className="big_txt">
                    <a href="#" onClick={(e) => {e.preventDefault(); this.props.handleToPage("reg")}}>Протестировать демо-версию</a>
                </div>
            </div>
        );
    }
}

IndexPage.propTypes = {
    handleToPage: PropTypes.func.isRequired, // func используется для проверки передачи function
};

export default IndexPage;