import React, {Component} from 'react';
import $ from "jquery";
import "jquery-serializejson";
import jcf from "jcf";
import validate from "./validate.polyfills";
import Parser from 'html-react-parser';

class RegPage extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleType = this.handleType.bind(this);
        this.handleFile = this.handleFile.bind(this);
    }

    handleType(event, a, b) {
        var self = this;
        setTimeout(function () {
            self.props.handleToPage(a, b);
        });

        event.preventDefault();
    }

    componentDidMount() {
        jcf.setOptions('Select', {
            wrapNative: false,
            wrapNativeOnMobile: true,
            fakeDropInBody: false
        });
        jcf.setOptions('File', {
            placeholderText: "Выберите фото",
        });

        jcf.replaceAll($('.input'));
        var self = this;
        $(function () {
            validate.init({
                disableSubmit: true, // If true, don't submit the form to the server (for Ajax for submission)
                onSubmit: function (form, fields) {
                    var formData = $(form).serializeJSON();
                    var isStart = true;
                    $.each(formData, function (key, value) {
                        if ($.trim(value) === "") {
                            isStart = false;
                        }
                    });

                    if (isStart === true) {
                        self.props.setUser(formData);
                        $.ajax({
                            method: "POST",
                            url: "sender.php",
                            data: {profile: formData}
                        }).done(function () {

                        }).fail(function () {

                        }).always(function () {

                        });
                        document.activeElement.blur();
                        $("input:focus").blur();
                    }
                }, // Function to run if the form successfully validates
            });
        });


    }

    pageState() {
        var base = {
            IndexPage: "none",
            PlantPage: "none",
            HumanPage: "none",
        };
        switch (this.props.action) {
            default:
            case "index":
                base.IndexPage = "block";
                break;
            case "plant":
                base.PlantPage = "block";
                break;
            case "human":
                base.HumanPage = "block";
        }


        return base;
    }

    handleSubmit(event) {

        event.preventDefault();
    }

    handleFile(event){
        event.preventDefault();
        var file = event.target.files[0];
        var fr = new FileReader;
        fr.onloadend = function (str) {
            if(typeof str === "object") {
                window.user_photo = str.target.result; // file reader
            }
        };
        fr.readAsDataURL(file);
    }

    render() {
        const {display} = this.props;
        var state = this.pageState();
        var user_age = "";
        for (var i =2012; i >= 1917; i--){
            user_age+= "<option>"+i+"</option>";
        }

        return (
            <div className="container" style={{display: display}}>
                <form className="form form2" action="/" style={{display: state.IndexPage}}>
                    <div className="form_title">
                        Представьтесь, пожалуйста
                    </div>
                    <div className="centr_block">
                        <label htmlFor="ddd2" className="custom_check_hold" onClick={(e) => {
                            e.preventDefault();
                            $("#" + $(e.target).attr('for')).prop("checked", true);
                            this.handleType(e, "reg", "human");
                        }}>
                            <input id="ddd2" className="outtaHere" type="radio" name="rad1" value="human"/>
                            <span className="radio_btn" onClick={(e) => {
                                e.preventDefault();
                                $(e.target).parent().click();

                            }}/>
                            Человек ищущий
                        </label>
                        <label htmlFor="ddd1"  className="custom_check_hold" onClick={(e) => {
                            $("#" + $(e.target).attr('for')).prop("checked", true);
                            this.handleType(e, "reg", "plant");
                        }}>
                            <input id="ddd1" className="outtaHere" type="radio" name="rad1" value="plant"/>
                            <span className="radio_btn"  onClick={(e) => {
                                e.preventDefault();
                                $(e.target).parent().click();

                            }}/>
                            Опекун растения
                        </label>

                    </div>
                </form>
                <form className="form" action="/" method="post" encType="multipart/form-data" data-validate="true" style={{display: state.HumanPage}}>
                    <div className="input">
                        <label className="label">ВАШЕ ИМЯ</label>
                        <input type="text" name="user_name" required="required"/>
                    </div>
                    <div className="input">
                        <label className="label">ГОД РОЖДЕНИЯ</label>
                        <select data-jcf='{"maxVisibleItems": 5}' name="user_age" required="required">
                            <option className="placeholder"/>
                            {Parser(user_age)}
                        </select>
                    </div>
                    <div className="input">
                        <label className="label">ПОЛ</label>
                        <select data-jcf='{"maxVisibleItems": 5}' name="user_sex" required="required">
                            <option className="placeholder"/>
                            <option>Не определен</option>
                            <option>Мужской</option>
                            <option>Женский</option>
                        </select>
                    </div>
                    <div className="input">
                        <label className="label">СФЕРА ДЕЯТЕЛЬНОСТИ</label>
                        <select data-jcf='{"maxVisibleItems": 5}' name="user_prof" required="required">
                            <option className="placeholder"/>
                            <option>Бухгалтерия, финансы, право</option>
                            <option>Искусство и культура</option>
                            <option>Красота/спорт/туризм</option>
                            <option>Маркетинг, реклама, PR</option>
                            <option>Медицина</option>
                            <option>Научно-исследовательская деятельность</option>
                            <option>Образование</option>
                            <option>Политика</option>
                            <option>Производство/инженерия</option>
                            <option>Сфера услуг</option>
                            <option>Торговля</option>
                            <option>Транспорт</option>
                            <option>HR и психология</option>
                            <option>IT и коммуникации</option>
                            <option>Другое</option>
                        </select>
                    </div>
                    <div className="input">
                        <label className="label">E-MAIL</label>
                        <input type="email" name="user_email" required="required"/>
                    </div>
                    <div className="input">
                        <span className="label">ФОТО</span>
                        <input type="file" name="photo" onChange={this.handleFile} accept="image/jpeg,image/png,image/gif"/>
                    </div>
                    <div className="btn_hold">
                        <button className="btn" type="submit"><span className="btn_in">НАЧАТЬ ПОИСК</span></button>
                    </div>
                    <input type="hidden" name="user_type" value="humans"/>
                </form>
                <form className="form" action="/" method="post" encType="multipart/form-data" data-validate="true" style={{display: state.PlantPage}}>
                    <div className="input">
                        <label className="label">НАЗВАНИЕ ВАШЕГО РАСТЕНИЯ</label>
                        <input type="text" name="user_name" required="required "/>
                    </div>
                    <div className="input">
                        <label className="label">ВОЗРАСТ РАСТЕНИЯ</label>
                        <select data-jcf='{"maxVisibleItems": 5}' name="user_age" required="required">
                            <option className="placeholder"/>
                            <option>менее 1 года</option>
                            <option>1 год</option>
                            <option>2 года</option>
                            <option>3 года</option>
                            <option>4 года</option>
                            <option>5 лет</option>
                            <option>6 лет</option>
                            <option>7 лет</option>
                            <option>8 лет</option>
                            <option>9 лет</option>
                            <option>10 лет</option>
                            <option>более 10 лет</option>
                        </select>
                    </div>
                    <div className="input">
                        <label className="label">СОСТОЯНИЕ ЗДОРОВЬЯ</label>
                        <select data-jcf='{"maxVisibleItems": 5}' name="user_med" required="required">
                            <option className="placeholder"/>
                            <option>Отличное</option>
                            <option>Среднее</option>
                            <option>Плохое</option>
                        </select>
                    </div>
                    <div className="input">
                        <label className="label">ПРИЧИНА ПОИСКА НОВОГО ОПЕКУНА</label>
                        <select data-jcf='{"maxVisibleItems": 5}' name="user_searchop" required="required">
                            <option className="placeholder"/>
                            <option>нет возможности заботиться</option>
                            <option>нет желания заботиться</option>
                            <option>не умею ухаживать</option>
                        </select>
                    </div>
                    <div className="input">
                        <label className="label">E-MAIL</label>
                        <input type="email" name="user_email" required="required"/>
                    </div>
                    <div className="input">
                        <span className="label">ФОТО</span>
                        <input type="file" name="photo" onChange={this.handleFile} accept="image/jpeg,image/png,image/gif"/>
                    </div>
                    <div className="btn_hold">
                        <button className="btn" type="submit"><span className="btn_in">НАЧАТЬ ПОИСК</span></button>
                    </div>
                    <input type="hidden" name="user_type" value="plant"/>
                </form>
            </div>
        );
    }
}

export default RegPage;